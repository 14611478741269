@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  font-family: 'Montserrat', sans-serif !important;
  background-color: black;
}

h1 {
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

h2 {
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

p {
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}

#heroSection {
  height: 100vh;
  display: flex;
  align-items: center;
}

#myNavbar {
  position: absolute;
  overflow: hidden;
  top: 0px;
  /* padding: 1rem 5rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  width: 100%;
}

#navbarLogo {
  font-style: italic;
  font-weight: bold;
  font-size: 25px;
}

.navLink {
  color: white;
  font-weight: 600;
  margin-left: 2rem;
  cursor: pointer;
}

#heroContainer {
  padding: 1rem 5rem;
}

#heroHeader {
  font-size: 75px;
  font-weight: bold;
}

#heroSubheader {
  font-size: 16px;
}

#heroSectionArrowDownIcon {
  color: white;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 1rem;
}

.section {
  padding: 1rem 5rem;
  /* height: 100vh; */
}

.sectionHeader {
  font-size: 37.5px;
  font-weight: bold;
  /* margin-bottom: 2rem; */
}

.sectionSubheader {
  margin-bottom: 2rem;
}

.iconCard {
  background-color: #111111;
  width: 190px;
  padding: 1rem;
  margin-right: 1rem;
  text-align: center;
  border-radius: 10px;
}

.iconCard svg {
  color: #FFFFFF;
  width: 40px;
  height: 40px;
  margin-bottom: 0.5rem;
}

.myExperienceCard {
  /* background-color: #111111; */
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0rem;
  text-align: center;
  border-radius: 10px;
  /* margin-bottom: 1rem; */
  display: flex;
  flex-direction: row;
  text-align: left;
}

.myExperienceImageContainer img {
  height: 77px;
  border-radius: 10px;
  margin-right: 1rem;
  /* filter: grayscale(100%); */
}

.experienceRole {
  font-weight: bold;
  font-size: 16px;
}

.experienceDates, .experienceLocation {
  font-size: 14px;
}

.projectsCard {
  width: 500px;
  background-color: #111111;
  border-radius: 10px;
  margin-right: 1.5rem;
  height: 100%;
}

.projectsCardTextContainer {
  padding: 1rem;
}

.projectsCard img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* filter: grayscale(100%); */
}

.projectsCardTitle {
  font-weight: bold;
}

.projectsCardDescription {
  font-size: 14px;
}

.inputLabel {
  font-size: 14px;
  margin-bottom: 0.25rem;
}

input {
  background-color: #000000;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  box-shadow: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  margin-bottom: 0.5rem;
}

textarea {
  background-color: #000000;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  box-shadow: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  margin-bottom: 0.5rem;
  font-family: 'Arial', sans-serif;
}

.primaryBtn {
  background-color: #FFFFFF;
  box-shadow: none;
  outline: none;
  border: 1px solid #FFFFFF;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  color: #000000;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #222222;
}

footer {
  padding: 1rem 5rem;
  padding-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#footerSocialIconsContainer {
  display: flex;
  flex-direction: row;
}

.footerSocialIconContainer {
  width: 50px;
  height: 50px;
  background-color: #FFFFFF;
  border-radius: 100px;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

footer svg {
  width: 20px;
  height: 20px;
  color: black
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar {
  display: none;
}

.swal2-popup {
  background-color: #222222;
  color: #FFFFFF;
  border-radius: 10px !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=left] {
  background-color: #222222 !important;
}

div:where(.swal2-icon).swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  background-color: #222222 !important;
}

div:where(.swal2-icon).swal2-success .swal2-success-fix {
  background-color: #222222 !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  color: #222222 !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 10px !important;
}

.loadingPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

#sidebar {
  background-color: #111111;
  position: absolute;
  right: 0px;
  height: 100vh;
  width: 75%;
  z-index: 100;
}

#sidebarContainer {
  padding: 1rem;
}

#sidebar svg {
  color: #FFFFFF;
  width: 40px;
  height: 40px;
  margin-bottom: 2rem;
}

#sidebar .navLink {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 2rem;
  display: block;
}

#sidebar .navLanguageToggle {
  margin-left: 0px;
  justify-content: center;
}

#sidebar .navLanguageToggle p {
  font-size: 24px;
}

.navLanguageToggle {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}

.navLanguageToggle p {
  margin-bottom: 0px;
  cursor: pointer;
}

.navLanguageToggle p.active {
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }

  #menuToggler {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }

  #heroContainer {
    padding: 1rem 1rem;
  }

  .section {
    padding: 1rem 1rem;
  }

  #myNavbarContainer {
    padding: 1rem 1em !important;
  }

  #desktopNavbarItems {
    display: none !important;
  }

  #menuToggler {
    color: white;
    width: 40px;
    height: 40px;
  }

  #heroHeader {
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 1rem;
  }

  .iconCard {
    width: 45vw;
  }

  .myExperienceCard {
    padding: 0.5rem 0rem;
  }

  .projectsCard {
    width: 75vw;
  }

  footer {
    padding: 1rem 1rem;
    padding-top: 5rem;
  }
}